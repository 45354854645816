import React from "react"

import Layout from "../components/layout-static"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Page = () => {
  const metadata = {
    title: "Sexual Abuse Attorney",
    description:
      "At Bartlett & Grippe, LLC, we are here to help survivors of sexual abuse maximize compensation and empower their voices. Contact us now for support. Let us help you move forward and heal.",
    heroH1: "Sexual Abuse Attorney",
    heroH2: "Representing Children and Adult Victims of Childhood Sex Abuse",
    heroImage: "blg-child-dock.jpg",
    heroImageAlt: "Photo of a child victim of sexual abuse near a dock",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2 className="uppercase mb-8">Giving Victims a Voice</h2>
            <p className="mb-8">
              Our expert sexual abuse attorneys provide representation,
              assistance, and much-needed closure to victims, including adult
              victims of childhood sexual abuse or molestation. Childhood sex
              abuse often comes with guilt or fear on the part of the victim,
              who may have found it easier to say nothing than to face what may
              have literally been an unspeakable scenario.
            </p>
            <p>
              The victim may silently carry those same feelings into adulthood
              until he or she is finally able to speak up to help the healing
              process. These sex abuse acts are often committed by trusted
              people in the community such as priests, doctors, teachers,
              coaches, medical professionals, Scout leaders, and even family
              members. The victims often feel that they are alone and that
              people won’t believe their stories. We will believe you, and help
              you and your family members who are still suffering from abuse
              issues that may have happened years ago. Our intent is to empower
              the voice that has so long been silent and to maximize your
              compensation. Our goal is to help victims of sexual molestation
              and sexual abuse become survivors and move on with their lives.
            </p>
            <Link to="/contact-us/">
              <button className="mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
          </div>
          <div className="mb-16">
            <h2 className="uppercase mb-8">Experience</h2>
            <p className="mb-8">
              Our sexual abuse attorneys have represented many victims of sexual
              abuse and assault. They have brought numerous cases to trial and
              have successfully argued several cases before the Connecticut
              Superior Court, Connecticut Appellate Court, and the Connecticut
              Supreme Court.
            </p>
            <div className="mb-8 text-center">
              <StaticImage
                className=""
                width="600"
                src="../images/Top-rated-injury-trial-lawyer-300x150.jpg"
                formats={["auto", "webp", "avif"]}
                alt="Sex abuse attorney talking in front of jurors at trial"
              />
            </div>
            <p className="mb-8">
              Our lead sex abuse attorney has served as lead counsel in one of
              the representative cases selected for trial in the largest sexual
              abuse case against a medical professional in Connecticut history.
              The case was against St. Francis Hospital whose head of
              endocrinology, Dr. George Reardon, photographed and fondled many
              of his young patients. In 2007, Reardon’s hidden pornographic
              library of child patients was found in his former West Hartford
              home.
            </p>
            <p className="mb-8">
              Frank was very fortunate to have worked with the late Attorney
              Clark of Seattle, one of the nation’s leaders in helping eliminate
              sexual abuse in scouting. Attorney Clark was co-counsel with
              Attorney Bartlett on several Boy Scouts of America abuse cases in
              Connecticut. For over thirty years Attorney Clark represented
              hundreds of child molestation victims in litigation against what
              he called “institutions of trust” — the Catholic Church, and the
              Boy Scouts of America. He helped force mammoth and beloved
              organizations to concede to decades of sexual abuse within their
              ranks, or to efforts to keep knowledge of abuse secret from the
              public. The knowledge Attorney Bartlett gained by working with
              Attorney Clark has been invaluable in representing sexually abused
              here in Connecticut.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              We have been asked the same questions by many clients. Below are
              the answers to some of these important sexual abuse questions.
            </h2>
            <h3 className="uppercase">
              1) How much is my sexual abuse case worth?
            </h3>
            <p className="mb-8">
              The value of every case is different and the maximum value can
              only be determined by an experienced attorney. Many factors go
              into determining the true value and these will be discussed in
              detail with you. While every case is different, and past results
              do not ensure future success, we are very proud that we have
              secured millions of dollars in settlements and awards for our
              sexually abused clients. Clients have told us that the closure
              this provides is more valuable than the money. Below is a sampling
              of the types of sexual abuse cases we have successfully handled
              for past clients, and where allowable the results we have
              obtained.
            </p>
            <p className="mb-8">
              Through the civil justice system, we seek validation for our
              clients. As one example, Attorney Bartlett won a $2,750,000
              verdict for a woman that was molested as a child by her mother’s
              boyfriend forty years ago. Despite the fact that there were few
              records to support her claim, the jury sided with sexual abuse
              attorney Bartlett.
            </p>
            <p className="mb-8">
              Bartlett & Grippe, LLC has represented many Boy Scouts who have
              been sexually abused by Connecticut Scout Leaders such as Alton
              Parady in southern Connecticut, and David (Dirk) Davenport of
              Madison, CT.
            </p>
            <p className="mb-8">
              John Roe #1 v. Boy Scouts of America, et al. – $1,000,000 judgment
              obtained against a Boy Scout scoutmaster on behalf of a young boy
              who suffered sexual abuse at scouting events and at a local Boy
              Scout camp.
            </p>

            <h3 className="uppercase">2) What if I don’t have records?</h3>
            <p className="mb-8">
              The majority of our sex abuse cases deal with adult victims of
              sexual abuse as children. The abuse often happened many years ago
              and there may be very few records associated with the events.
              Bartlett & Grippe and our team of experts work together with you
              to piece together the facts and records. If your case goes to
              trial we will not pass you on to another attorney. We have the
              trial experience to take your case to court and up through the
              appeals process if necessary. We will be by your side through the
              entire process. Continuity is critical to obtaining the best
              possible outcome.
            </p>

            <h3 className="uppercase">
              3) What types of sexual abuse cases have you handled?
            </h3>
            <p className="mb-8">
              We represent clients who have been sexually abused by Boy Scout
              leaders, priests, medical professionals, family members, teachers,
              and others. Attorney Bartlett is our lead trial attorney dealing
              with sexual abuse cases. He will handle your case every step of
              the way, in every court required. He has handled many types of
              sexual abuse cases, in the courtroom and through arbitration. This
              is a specialized form of legal advocacy that should be handled by
              an attorney with experience in this field of law. We understand
              the medical, psychological, and legal issues involved in a sexual
              abuse claim. We know how to build strong, convincing cases
              designed to secure deserved compensation for our clients. Child
              sex abuse cases often settle without going to court.
            </p>

            <h3 className="uppercase">
              4) Do I need an attorney if I’m going to settle?
            </h3>
            <p className="mb-8">
              You don’t need an attorney to settle. You do need an attorney to
              reach the best settlement for you. Countless hours of work and
              investigation go into every case. Each case at Bartlett & Grippe,
              LLC is handled as if it will eventually go to trial. This hard
              work and our reputation will help you receive the maximum value
              for your case. If our firm accepts your case, you will not be
              shuffled off to an associate. If the case goes to trial you will
              not be sent to another firm.
            </p>

            <h3 className="uppercase">
              5) How long do I have to initiate a lawsuit? What is the
              Connecticut statute of limitation associated with childhood sexual
              abuse?
            </h3>
            <p className="mb-8">
              Although Connecticut law does have strict statutes of limitations
              for filing criminal charges, most people don’t realize that
              childhood sexual abuse and other types of civil claims may still
              be filed, in many instances, at any time before the victim reaches
              age 48.
            </p>
            <p className="mb-8">
              <strong>Also, Conn. Gen. Stat. §52-577e states: </strong>There is
              no statute of limitations for suits against abusers who have been
              convicted of sexual assault in the 1st degree. Our attorneys can
              evaluate the facts and circumstances of your particular case to
              determine if you are still within the applicable statute of
              limitations.
            </p>
            <p className="mb-8">
              There is no statute of limitations for suits against abusers who
              have been convicted of sexual assault in the 1st degree.
            </p>

            <h3 className="uppercase">
              6) What should I expect at our first meeting and how long will it
              take?
            </h3>
            <p className="mb-8">
              At the initial consultation, clients receive immediate validation
              and support. Through discussion with you, we begin to understand
              the events that led you to seek legal counsel and the pertinent
              details about the incident(s). Initial consultation appointments
              are generally one hour in length. When you become our client, you
              are no longer alone in this journey for validation and closure.
              Our clients have told us of the feeling of great relief they have
              experienced when they finally understood that the abuse was not
              their fault. The focus of our work together is to hold the abuser
              accountable while helping survivors take a courageous step in the
              direction of justice and emotional freedom. During the initial
              confidential consultation, our expert sex abuse attorneys will
              discuss the need for treatment and counseling ahead of a complex
              and often lengthy legal process. Your well-being is the most
              important factor.
            </p>

            <h3 className="uppercase">7) Will my secret be made public?</h3>
            <p className="mb-8">
              One thing we repeatedly hear is the need for confidentiality. We
              understand this and do everything possible to keep your matters
              private. If we are required to represent you in court we will not
              use your name but will use an alias such as John Doe if that is
              what you want. We have found that almost all cases are settled
              rather than go to trial. A November 2015 article in the
              Connecticut Law Tribune stated that over 95% of these cases settle
              before going to trial.
            </p>

            <h3 className="uppercase">
              8) Can the medical issues I’m having be related to the abuse?
            </h3>
            <p className="mb-8">
              Victims of sex abuse or sexual assault often suffer lifelong
              issues. Many of our sexually abused clients suffer from a variety
              of illnesses that are commonly related to past sexual abuse such
              as depression, post-traumatic stress disorder, anxiety, drug, and
              alcohol abuse, eating disorders, and difficulty maintaining a
              long-term relationship. Understandably, the sexually abused often
              have trust issues. Because childhood sexual abuse can leave a
              severe emotional scar on a person’s life, our attorneys encourage
              our clients to seek the treatment and counseling they need if they
              have not done so already.
            </p>
          </div>
          <div className="mb-16">
            <h3 className="uppercase">
              We represent clients with sexual abuse by Boy Scout leaders,
              priests, medical professionals, teachers, and others
            </h3>
            <p className="mb-8">
              Please click on one of these links for specific information.
            </p>
            <ul className="list-disc list-inside mb-8 ml-6">
              <li>
                <Link to="/ct-sex-abuse-attorney/sexual-abuse-by-priests/">
                  Priest
                </Link>
              </li>
              <li>
                <Link to="/ct-sex-abuse-attorney/child-sexual-abuse-attorney/">
                  A family member, foster parent
                </Link>
              </li>
              <li>
                <Link to="/ct-sex-abuse-attorney/sexual-abuse-medical-professionals/">
                  Medical Professional
                </Link>
              </li>
              <li>
                <Link to="/ct-sex-abuse-attorney/child-sexual-abuse-attorney/">
                  Teacher
                </Link>
              </li>
              <li>
                <Link to="/ct-sex-abuse-attorney/ct-boy-scout-sexual-abuse-lawyer/">
                  Boy Scout sexual abuse
                </Link>
              </li>
            </ul>
          </div>
          <div className="mb-16">
            <h2 className="text-center uppercase">
              CT Child Sexual Abuse Attorney
            </h2>
            <div className="mb-8 text-center">
              <StaticImage
                className=""
                width="600"
                src="../images/i-sexualabusefamilymembers.jpg"
                formats={["auto", "webp", "avif"]}
                alt="Child upset due to sexual abuse"
              />
            </div>

            <h3 className="uppercase">
              Working with Child Sexual Abuse Victims and Families
            </h3>
            <p className="mb-8">
              Perpetrators of sexual abuse often utilize authority positions and
              the status and trust associated with those positions to befriend
              and exploit innocent children. Some examples are,
            </p>
            <ul className="list-disc list-inside">
              <li className="mb-4">
                <strong>Educational institutions </strong> such as The Indian
                Mountain Boarding School in Lakeville, Choate Rosemary Hall in
                Wallingford, or Newington High School
              </li>
              <li className="mb-4">
                <strong>Religious institutions </strong> like the Catholic
                Church or the United Church of Christ
              </li>
              <li className="mb-4">
                <strong>Civic organizations</strong> such as the{" "}
                <Link to="/ct-sex-abuse-attorney/ct-boy-scout-sexual-abuse-lawyer/">
                  Boy Scouts of America
                </Link>
                .
              </li>
            </ul>
          </div>
          <div className="mb-16">
            <h2 className="text-center uppercase">
              Foster Parent and Family Member Sexual Abuse Lawyers
            </h2>
            <p className="mb-8">
              Foster parents wishing to open their homes to children should be
              thoroughly vetted prior to any licensure. Once a child is placed,
              Connecticut state agencies have the responsibility to provide a
              licensed and trained social worker to visit the home regularly.
              Part of that training involves identifying sexual abuse and
              molestation. Suspicion should lead to immediate investigations and
              possible removal of the child. Failure of the state’s
              representative to act not only allows the abuse to continue, but
              it also puts the state at risk of legal action along with the
              abusing foster parent.
            </p>
            <p className="mb-8">
              At the initial consultation, clients receive immediate validation
              as we spend time with you to understand the events that led you to
              seek legal counsel. In cases involving a family member, treatment
              and counseling are necessary for the victim, abuser, and other
              family members. The focus is to hold the abuser and state
              agencies, when appropriate, accountable. When abuse is committed
              by a foster parent or close relative, additional issues need
              resolution. Preserving familial relationships are vital. The
              future of a family unit is often at stake and requires attention
              as well.
            </p>
            <p className="mb-8">
              Unwanted sexual contact by a family member adds a particularly
              emotionally charged dynamic. Clients are encouraged to seek the
              treatment and counseling they need, either ahead of any legal
              action or running concurrently with a lawsuit.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="text-center uppercase">Sex Abuse by Priests</h2>
            <h3 className="uppercase">
              We represent those sexually abused as children by priests. You are
              not alone. Bartlett & Grippe, LLC can help.
            </h3>
            <p className="mb-8">
              We have learned much over the years from our clients about the
              devastating long term effects and far-reaching consequences of
              childhood sexual abuse. We have seen how lives have been
              drastically changed due to the use of drugs and alcohol in an
              attempt to deal with abuse. We help you understand that this was
              not your fault and that you are not alone. Clients have expressed
              great relief by finally talking about their experiences, taking
              action, and having justice served. Our firm’s motto is “Giving
              Victims a Voice”. We often hear, why isn’t anyone doing anything?
              Bartlett & Grippe is doing something. We have successfully
              represented many children and adults sexually abused as children.
              We can help you, and you can help us make a difference. Our
              attorneys are parents and believe that a child is the most
              precious gift possible. Stealing your childhood by sex abuse can
              never be fully compensated. We will do our very best to help you
              get repaid for your losses, both physical and emotional.
            </p>
            <h3 className="uppercase">Helpful movie</h3>
            <p className="mb-8">
              The movie <strong>Spotlight</strong> gives an excellent account of
              how an entire city, country and world looked the other way
              concerning sexual abuse by priests. It is very common for those in
              power and someone is known to the child to be the abuser. Stranger
              Danger is not the only thing to teach our young. Over 93% of those
              abused knew the abuser. Spotlight is a movie worth seeing. The
              movie shows the complexities of dealing with abusers that are
              respected in the community. For more information about the making
              of <strong>Spotlight</strong> go to
              www.bostonglobe.com/arts/movies/spotlight-movie
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>

            <h3 className="uppercase">
              9) What are the numbers associated with sex abuse in the Catholic
              Church and are they True?
            </h3>
            <p className="mb-8">
              There are many statistics concerning the thousands of priests in
              the United States accused of sex abuse. There are reportedly over
              44 priests accused of molestation or sexual abuse in Connecticut
              alone. At Bartlett & Grippe, LLC our main concern is you, and how
              we can help make your life better. Again, the number we are
              concerned with is one: You.
            </p>
            <p className="mb-8">
              Below are numbers reported by Frontline PBS provided by the
              Catholic Church in 2012.
            </p>

            <h3 className="uppercase">Other Resources</h3>
            <p className="mb-8">
              There are several organizations that are working towards an answer
              to this worldwide epidemic in the church.
            </p>
            <p className="mb-8">
              <strong>Survivors Network of Those Abused by Priests: </strong>
              <a href="https://www.snapnetwork.org">
                https://www.snapnetwork.org
              </a>
            </p>
            <p className="mb-8">
              <strong>CBS News May 2014 – The Vatican </strong>revealed how many
              priests were defrocked for sex abuse since 2004:{" "}
              <a href="https://www.cbsnews.com/news/vatican-reveals-how-many-priests-defrocked-for-sex-abuse-since-2004/">
                https://www.cbsnews.com/news/vatican-reveals-how-many-priests-defrocked-for-sex-abuse-since-2004/
              </a>
            </p>
          </div>
          <div className="mb-16">
            <h2 className="text-center uppercase">
              Sexual Abuse by Connecticut Medical Professionals
            </h2>
            <div className="mb-8 text-center">
              <StaticImage
                quality="100"
                width="600"
                src="../images/i-sexualabusemedicalprof.jpg"
                formats={["auto", "webp", "avif"]}
                alt="Sexual abuse by medical professionals"
              />
            </div>
            <h3 className="uppercase">
              Empowering Victims Of Sexual Abuse By Medical Personnel
            </h3>
            <p className="mb-8">
              Medical professionals or doctors who commit sexual abuse against
              helpless patients should be punished by our criminal justice
              system. Licensing boards can take action as well if a complaint is
              filed. Yet, prison sentences, sex offender registration and the
              loss of professional licenses do little, if anything to promote
              the healing of victims. The attorneys at Bartlett & Grippe, LLC
              focus on protecting the rights and looking out for the best
              interests of their clients victimized by a trusted doctor.
            </p>
            <h3 className="uppercase">Giving Sexual Abuse Victims A Voice</h3>
            <p className="mb-8">
              Our goal is to transform something that was beyond your control
              into empowerment. Nonconsensual sexual contact by a medical
              professional not only violates the American Medical Association’s
              Principles of Medical Ethics, but it also breaches the trust
              placed in doctors, nurses or anesthetists.
            </p>
            <p className="mb-8">
              The basis for civil action is determined by the Sexual Abuse or
              misuse of the relationship between a licensed health care provider
              and a patient. While being proactive in putting our clients in
              control by holding an abuser accountable through a legal claim,
              lawsuits are not always the initial step.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="text-center uppercase">
              Sexual Abuse by Boy Scout Leaders
            </h2>
            <div className="mb-8 text-center">
              <StaticImage
                quality="100"
                width="600"
                src="../images/i-sexualabuseboyscoutinst-300x91.jpg"
                formats={["auto", "webp", "avif"]}
                alt="Sexual abuse by boy scout leaders"
              />
            </div>
            <p className="mb-8">
              <Link to="/ct-sex-abuse-attorney/ct-boy-scout-sexual-abuse-lawyer/">
                For information on sexual abuse by Boy Scout leaders please
                click here.
              </Link>
            </p>
            <p className="mb-8">
              The Bartlett & Grippe personal injury law firm is centrally
              located in Cheshire, Connecticut. We handle sexual abuse cases
              across the state in every town. We take referrals of sex abuse
              cases from attorneys across the country. Don’t settle for less,
              choose Bartlett & Grippe.
            </p>
            <p className="mb-8">
              <strong>
                Sources: above notes, attachments,
                http://www.cga.ct.gov/2013/rpt/2013-R-0162.htm
              </strong>{" "}
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Our mission at Bartlett & Grippe is to be the best sexual abuse
              law firm in Connecticut and to give victims a voice.
            </h2>
            <p className="mb-8">
              Our personal injury sex abuse attorney has extensive experience
              with many forms of sexual abuse cases, in the courtroom and
              through arbitration. He is knowledgeable about the laws,
              procedures, and intricacies that pertain to these cases. This is a
              specialized form of legal advocacy that should be handled by an
              attorney with experience in this field of law. We understand the
              medical, psychological, and legal issues involved in a sexual
              abuse claim. We know how to build strong, convincing cases
              designed to secure deserved compensation for our clients. Child
              sex abuse cases often settle without going to court, especially
              cases against large institutions that have dealt with our firm on
              other cases. If your case does go to court confidentiality may be
              maintained by the use of an alias such as John Doe under some
              circumstances. This can be discussed at our first consultation.
            </p>
            <h3 className="uppercase">
              A Victory for Victims: NY Advocates for Childhood Sexual Abuse
              Victims With Signing of Child Victims Act
            </h3>
            <p className="mb-8">
              Sexual abuse victims and advocates in New York have won a major
              victory with the passing of the Child Victims Act. This series of
              pro-victim amendments focus on expanding the statute of
              limitations for criminal and civil suits while also providing all
              sexual abuse victims an opportunity to bring forth a claim against
              any person or institution.
            </p>
            <p className="mb-8">
              The primary provisions of the Child Victims Act relating to sexual
              abuse in New York are:
            </p>
            <ul className="mb-8 list-disc list-outside ml-6">
              <li>
                The establishment of a one-time, one-year window (starting
                August 14, 2019) during which victims can file a lawsuit against
                their abuser, no matter when the abuse occurred or if the case
                had been dismissed due to prior laws
              </li>
              <li>
                A five-year extension, from 23 years of age to 28 years of age,
                in the amount of time a child has to file a criminal lawsuit
                against their abuser before the statute of limitations goes into
                effect
              </li>
              <li>
                The establishment of 55 years of age as the maximum age at which
                a victim can file a civil lawsuit against their abuser before
                the statute of limitations goes into effect
              </li>
              <li>
                The elimination of notice of claim requirements that had
                previously prevented victims from filing lawsuits against
                institutions accused of systemic abuse
              </li>
              <li>
                An increase in required judicial training on the topic of
                childhood sexual abuse
              </li>
            </ul>
            <p className="mb-8">
              Sexual abuse survivors and supporters have been fighting to
              protect children and humanize these laws for many years and are
              encouraged to see government officials helping to make a
              difference. One important advocate, New York Governor Andrew
              Cuomo, expressed support for the Child Victims Act by attending
              the vote in late-January then signed the bill into law on February
              14th after overwhelming approval from the New York state House and
              Senate.
            </p>
            <h3 className="uppercase">
              What to Expect at Your Consultation with our Firm and Beyond
            </h3>
            <p className="mb-8">
              At the initial consultation, clients receive immediate validation
              and support. Through discussion with you, we begin to understand
              the events that led you to seek legal counsel and the pertinent
              details about the incident(s). Initial consultation appointments
              are generally one hour in length. When you become our client, you
              are no longer alone in this journey for validation and closure.
              Our clients have told us of the feeling of great relief they have
              experienced when they finally understood that the abuse was not
              their fault. The focus of our work together is to hold the abuser
              accountable while helping survivors take a courageous step in the
              direction of justice and emotional freedom.
            </p>

            <h3 className="uppercase">Results</h3>
            <p className="mb-8">
              Through the civil justice system, we seek validation for our
              clients. As one example, our sex abuse attorney won a $2,750,000
              verdict for a woman that was molested as a child forty years ago.
              Despite the fact that there were few records to support her claim,
              the jury sided with Attorney Bartlett.
            </p>
            <p className="mb-8">
              Bartlett & Grippe, LLC represents and has successfully represented
              Boy Scouts who have been sexually abused by Connecticut Scout
              Leaders such as Alton Parady in southern Connecticut, and David
              (Dirk) Davenport of Madison, CT. For additional information on Boy
              Scout sexual abuse click here.
            </p>
            <p className="mb-8">
              Attorney Bartlett was the lead sexual abuse attorney who
              negotiated a confidential settlement in one of the representative
              cases that resulted from the largest documented case of serial
              pedophilia and child pornography in Connecticut state history in
              which a trusted hospital doctor was fondling and taking
              inappropriate pictures of his child patients.{" "}
              <strong>Don’t think you don’t have a case.</strong>
            </p>
            <p className="mb-8">
              Call <span class="bg-phone-number">203-463-4939</span> to schedule a free consultation. If you or
              anyone you know has been abused let us help.
            </p>

            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
